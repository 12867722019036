.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.scroll-container {
  height: 100vh; /* 设置容器的固定高度，占据整个视口高度 */
  overflow-y: scroll; /* 显示垂直滚动条 */
  scrollbar-width: thin; /* Firefox 特定的滚动条宽度 */
  scrollbar-color: #888 #f1f1f1; /* Firefox 特定的滚动条颜色 */
}

.scroll-container::-webkit-scrollbar {
  width: 0.35em; /* 设置滚动条的宽度 */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滚动条滑块的背景色 */
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置滚动条滑块悬停时的背景色 */
}

.scroll-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 设置滚动条轨道的背景色 */
}

.scroll-container::-webkit-scrollbar-track:hover {
  background-color: #f1f1f1; /* 设置滚动条轨道悬停时的背景色 */
}

.scroll-container::-webkit-scrollbar-thumb:vertical {
  min-height: 20px; /* 设置滚动条滑块的最小高度 */
}

.content {
  padding-right: 8px; /* 给内容添加右侧间距，留出滚动条的空间 */
}
